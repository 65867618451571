/*컴포넌트별 drawer 창에 Cancel/Add 버튼 Form 디자인*/
.drawer-form-buttons {
  text-align: center;
}

.dashboard-branch-select {
  padding-right: 5px;
  width: 210px;
}

.drawer-button-desktop {
  display: flex !important;
}

.drawer-button-mobile {

  display: none !important;
}

.show-branch-select {
  width: 130px !important;
}

.show-search-word {
  width: 180px !important;
}

@media screen and (max-width: 500px) {

  .dashboard-branch-select {
    width: 130px !important;
  }

  .drawer-button-desktop {
    display: none !important;
  }

  .drawer-button-mobile {
    display: flex !important;
  }

  .show-search-word {
    width: 135px !important;
  }

  .show-branch-select {
    width: 100px !important;
  }
}
